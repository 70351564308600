<template>
  <div>
    <v-row class="mx-0">
      <v-col cols="1 px-0" v-if="$store.getters.permission('params delete')">
        <v-btn @click="delete_row()" depressed fab x-small color="error">
          <v-icon>delete</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-select
          @change="change_type($event)"
          dense
          outlined
          v-model="param.type"
          :items="types"
          label="סוג"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field
          :rules="[(v) => !!v || 'חובה']"
          dense
          outlined
          v-model="param.name"
        >
          <template slot="label">
            <strong class="red--text">*</strong>
            שם
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="3">
        <v-btn
          v-if="$store.getters.permission('params create')"
          :loading="param.save_loader"
          :disabled="param.save_loader"
          @click="$emit('save_param', param)"
          color="black white--text ms-3"
          >שמירה</v-btn
        >
      </v-col>
    </v-row>

    <DeleteDialog
      v-if="$store.getters.permission('params delete')"
      @delete_done="update_param()"
      @close_delete="delete_obj.delete_prop = false"
      :delete_obj="delete_obj"
    ></DeleteDialog>
  </div>
</template>

<script>
import DeleteDialog from "@/components/public/delete";
export default {
  props: ["param", "params", "param_index"],
  name: "Basic",
  data() {
    return {
      delete_obj: {
        delete_prop: false,
        delete_url: "params",
        title: "מחיקת קטגוריה",
        id: null,
      },
      types: [
        {
          text: "אופציות",
          value: "option",
        },
        {
          text: "שדה טקסט",
          value: "input",
        },
        {
          text: "סויץ",
          value: "switch",
        },
      ],
    };
  },
  components: {
    DeleteDialog,
  },
  methods: {
    update_param() {
      this.params.splice(this.param_index, 1);
    },
    delete_row() {
      if (this.param.id) {
        this.delete_obj.id = this.param.id;
        this.delete_obj.delete_prop = true;
      } else {
        this.params.splice(this.param_index, 1);
      }
    },
    change_type(ev) {
      if (ev != "input") {
        this.param.input_type = "text";
        this.param.value = null;
        this.param.color = "#000000";
      } else if (ev != "options") {
        this.param.options = [];
      }
      console.log(ev);
    },
  },
};
</script>
