<template>
  <div>
    <div v-if="param.type == 'option'">
      <v-row v-for="(option, i) in param.options" :key="i">
        <v-col cols="3">
          <v-file-input
            background-color="white"
            outlined
            dense
            v-model="option.image"
            prepend-icon=""
            prepend-inner-icon="image"
            label="תמונה"
            :rules="[
              () => !option.img_err || 'סוג התמונה לא מתאים  (jpg, jpeg, png)',
            ]"
          ></v-file-input>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :rules="[(v) => !!v || 'חובה']"
            dense
            outlined
            v-model="option.name"
          >
            <template slot="label">
              <strong class="red--text">*</strong>
              שם
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :rules="[(v) => !!v || 'חובה']"
            dense
            outlined
            v-model="option.value"
          >
            <template slot="label">
              <strong class="red--text">*</strong>
              שדה טקסט
            </template>
          </v-text-field>
        </v-col>
        <v-col  v-if="$store.getters.permission('params delete')">
          <v-btn @click="delete_option(option,i)" depressed icon color="error">
            <v-icon>delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-btn outlined  v-if="$store.getters.permission('params create')"  small @click="new_option()">אופציה חדשה</v-btn>
    </div>

    <div v-else-if="param.type == 'input'">
      <v-row>
        <v-col cols="3">
          <v-select
            dense
            :items="input_types"
            outlined
            v-model="param.input_type"
            label="סוג שדה"
          ></v-select>
        </v-col>
        <v-col cols="4" v-if="param.input_type == 'text'">
          <v-text-field
            :rules="[(v) => !!v || 'חובה']"
            dense
            outlined
            v-model="param.value"
          >
            <template slot="label">
              <strong class="red--text">*</strong>
              שדה טקסט
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="4" v-else>
          <v-text-field
            :rules="[(v) => !!v || 'חובה']"
            type="color"
            dense
            outlined
            v-model="param.color"
          >
            <template slot="label">
              <strong class="red--text">*</strong>
              צבע
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="param.type == 'switch'">
      <v-switch v-model="param.switch"></v-switch>
    </div>
  </div>
</template>

<script>
export default {
  name: "inputs",
  props: ["param", "param_index", "params"],
  data() {
    return {
      input_types: [
        {
          value: "color",
          text: "צבע",
        },
        {
          value: "text",
          text: "טקסט",
        },
      ],
    };
  },
  methods: {
    delete_option(option,i) {
      if(option.id) {
        this.$store.dispatch("public__request", {
          config:{
            url:`destroy_option/${option.id}`,
            method:"delete"
          }
        }).then(res=> {
          this.params[this.param_index].options.splice(i, 1);
        }).catch(err=> {
          console.log(err.response)
        })
      }else {
        this.params[this.param_index].options.splice(i, 1);
      }
    },
    new_option() {
      this.params[this.param_index].options.push({
        image: null,
        name: "",
        value: "",
        img_err: false,
      });
    },
  },
};
</script>
